.fileClassName {
  width: 9.6rem;
}

.fileClassName .am-modal-content {
  padding-top: 0;
}

.fileClassName .am-modal-content .am-modal-header {
  padding-bottom: 0.133333rem;
  margin-bottom: 0.266667rem;
  border-bottom: 0.026667rem solid #ececec;
}

.fileClassName .am-modal-content .am-modal-header .am-modal-title {
  font-size: 0.426667rem;
}

.fileContent {
  max-height: 12rem;
  overflow-y: auto;
}

.fileContent img {
  max-width: 8.533333rem;
  max-height: 13.333333rem;
}