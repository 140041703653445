.pageContent {
  height: 15.146667rem;
  overflow-y: auto;
}

.pageContent .item {
  background: #fff;
  padding: 0.266667rem 0.266667rem 0 0.266667rem;
  margin-bottom: 0.533333rem;
}

.pageContent .item .itemInner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 0.266667rem;
  border-bottom: 0.026667rem solid #f5f5f5;
}

.pageContent .item .title {
  color: #333333;
  font-size: 0.426667rem;
  margin-bottom: 0.133333rem;
}

.pageContent .item .info {
  color: #999999;
  font-size: 0.32rem;
}

.pageContent .item .baseInfo {
  flex: 1 1;
}

.pageContent .item .download {
  width: 1.333333rem;
  color: #333333;
  font-size: 0.373333rem;
}

.bottomLoading {
  padding: 0.266667rem;
  text-align: center;
  color: #666;
}