.pageItem {
  padding: 0.266667rem;
  margin-bottom: 0.266667rem;
  background: #fff;
  border-radius: 0.106667rem;
}

.pageItem h4 {
  padding-bottom: 0.213333rem;
  border-bottom: 0.013333rem dashed #c5c5c5;
  font-weight: 500;
  color: #333333;
  font-size: 0.426667rem;
  line-height: 0.586667rem;
}

.pageItem .content {
  font-size: 0.4rem;
  line-height: 0.56rem;
  padding-bottom: 0.133333rem;
}

.pageItem .content .kv {
  display: flex;
  align-items: baseline;
  margin-top: 0.4rem;
}

.pageItem .content .kv span {
  color: #a8a8a8;
  width: 2.933333rem;
  padding-right: 0.266667rem;
  text-align: right;
  flex-shrink: 0;
}

.pageItem .content .kv pre {
  font-family: Helvetica Neue, Helvetica, Roboto, Segoe UI, Arial, sans-serif;
  color: #333333;
  flex: 1 1;
  word-wrap: break-word;
  word-break: break-all;
  white-space: pre-wrap;
}

.pageItem .content .kv pre span {
  color: #1e9fff;
}

.pageItem .content .notitle {
  margin-top: 0.133333rem;
}