.handleOpinion {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 1.813333rem;
  background: #fff;
  padding: 0.266667rem;
}

.panelItem {
  margin-bottom: 0.32rem;
}

.panelItem .panelTitle {
  text-align: left;
  color: #333333;
  font-size: 0.373333rem;
  line-height: 0.533333rem;
}

.panelItem .radioContent {
  display: flex;
}

.panelItem textarea {
  width: 100%;
  background: #fbfbfb;
  resize: none;
  padding: 0.4rem 0.266667rem;
}

.panelItem .ridioItem {
  height: 0.666667rem;
  line-height: 0.666667rem;
  display: flex;
  align-items: center;
  text-align: left;
}

.panelItem .ridioItem > input {
  width: 0.426667rem;
  height: 0.426667rem;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.08rem;
  background-size: cover;
  background-repeat: no-repeat;
}

.panelItem .ridioItem label {
  width: 100%;
  height: 100%;
  flex: 1 1;
  height: 0.613333rem;
  vertical-align: middle;
}