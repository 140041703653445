.remindList .item {
  height: auto;
}

.remindList .item .title {
  height: auto;
}

.remindList .item p {
  display: block;
}

.remindList .item p span {
  display: block;
  margin-top: 0.133333rem;
}