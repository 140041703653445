.am-tabs-tab-bar-wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
}

.am-tabs-content-wrap {
  margin-top: 1.16rem;
}

::-webkit-scrollbar {
  display: none;
}

.stepsTime {
  padding-bottom: 1.866667rem !important;
}

.showInfo {
  padding-bottom: 1.866667rem !important;
}

.fileView {
  padding-bottom: 1.866667rem !important;
}