.searchContent label {
  display: block;
  width: 100%;
  height: 1.173333rem;
  padding: 0 0.346667rem;
  background: #fff;
}

.searchContent label input {
  width: 100%;
  height: 100%;
  flex: 1 1;
  text-align: right;
  font-size: 0.426667rem;
}

.searchContent label input[name='minInsuranceBalance'],
.searchContent label input[name='maxInsuranceBalance'] {
  text-align: center;
}

.searchContent label .innerLabel {
  display: flex;
  height: 100%;
  line-height: 1.173333rem;
  border-bottom: 0.026667rem solid #e5e5e5;
}

.searchContent label .innerLabel > span {
  width: 2.8rem;
  font-size: 0.453333rem;
}

.searchContent label:nth-last-child(2) .innerLabel {
  border-bottom: 0;
}

.searchContent .am-list-item {
  padding: 0 0.346667rem;
}

.searchContent .am-list-item .am-list-line {
  border-bottom: 0.026667rem solid #e5e5e5;
  padding: 0;
}

.searchContent .am-list-item .am-list-line .am-list-extra {
  flex-basis: 60%;
}

.searchContent .handleOpinion {
  left: 0;
}

.searchContent .handleOpinion input {
  display: block;
  outline: 0 none;
  -webkit-appearance: none;
  box-sizing: border-box;
  padding: 0;
  text-align: center;
  font-size: 0.48rem;
  height: 1.28rem;
  line-height: 1.28rem;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  white-space: nowrap;
  color: #fff;
  background-color: #108ee9;
  border: 0.026667rem solid #108ee9;
  border-radius: 0.133333rem;
  position: relative;
  width: 100%;
}

.clientList {
  padding-top: 0.266667rem;
}

.clientList .item {
  height: auto;
}

.clientList .item .title {
  height: auto;
}

.clientList .item p {
  display: block;
}

.clientList .item p span {
  display: block;
  margin-top: 0.133333rem;
}