.goBackHome {
  display: block;
  width: 100%;
  color: #1e9fff;
  font-size: 0.48rem;
  padding: 0.266667rem;
  position: fixed;
  top: 0;
  background: #fff;
}

.listContainer {
  height: 17.786667rem;
  overflow-y: auto;
  padding: 1.333333rem 0.266667rem 0.266667rem 0.266667rem;
}

.listContainer .item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 2.4rem;
  margin-bottom: 0.266667rem;
  padding: 0.266667rem;
  background: #fff;
  border-radius: 0.106667rem;
}

.listContainer .item .title {
  height: 1.173333rem;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  font-weight: 500;
  color: #333333;
  font-size: 0.426667rem;
  line-height: 0.586667rem;
}

.listContainer .item p {
  display: flex;
  justify-content: space-between;
  color: #666666;
  font-size: 0.373333rem;
  line-height: 0.533333rem;
}

.listContainer .bottomLoading {
  padding: 0.266667rem;
  text-align: center;
  color: #666;
}