.pageContent .item_guarantee {
  background: #fff;
  padding: 0.266667rem;
}

.pageContent .item_guarantee .itemInner_guarantee {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pageContent .item_guarantee .info {
  color: #666;
  font-size: 0.373333rem;
  margin-bottom: 0.213333rem;
}

.pageContent .item_guarantee .info:first-child span:first-child {
  color: #fff;
  padding: 0.053333rem 0.133333rem;
  background-color: #1e9fff;
  border-radius: 0.106667rem 0 0.106667rem 0;
  margin-right: 0.133333rem;
}

.pageContent .item_guarantee .info:first-child span:last-child {
  font-weight: 500;
  font-size: 0.426667rem;
  color: #333;
}

.pageContent .item_guarantee .info:last-child {
  margin-bottom: 0;
}

.pageContent .item_guarantee .baseInfo {
  flex: 1 1;
}

.pageContent .item_guarantee .gotoLink {
  width: 0.4rem;
  color: #333333;
  font-size: 0.373333rem;
}

.bottomLoading {
  padding: 0.266667rem;
  text-align: center;
  color: #666;
}