.menuItem {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 2.977867rem;
  height: 3.2rem;
  padding: 0.586667rem 0 0.533333rem 0;
  background-color: #fcf8ff;
  border-radius: 0.266667rem;
}

.menuItem img {
  width: 1.28rem;
  height: 1.28rem;
}

.menuItem p {
  font-weight: 500;
  color: #333333;
  font-size: 0.426667rem;
}

.menuItem span {
  position: absolute;
  width: 0.8rem;
  height: 0.8rem;
  text-align: center;
  line-height: 0.8rem;
  top: 0.266667rem;
  right: 0.4rem;
  font-size: 0.32rem;
  color: #fff;
  background: #ff3746;
  border-radius: 50%;
}