.stepItem .time {
  font-size: 0.373333rem;
  color: #666;
}

.stepItem .time::before {
  content: '';
  display: inline-block;
  margin-right: 0.266667rem;
  width: 0.133333rem;
  height: 0.133333rem;
  border: 0.066667rem solid #1e9fff;
  border-radius: 50%;
}

.stepItem:last-child .warpContent {
  border-left: 0;
}

.warpContent {
  padding: 0.133333rem 0 0.533333rem 0.373333rem;
  border-left: 0.026667rem solid #ccc;
  margin-left: 0.106667rem;
}

.warpContent .content {
  background: #fff;
  border-radius: 0.106667rem;
  padding: 0.32rem 0.453333rem 0.533333rem 0.266667rem;
}

.warpContent .content .baseInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.warpContent .content .baseInfo .task {
  font-weight: 500;
  color: #333333;
  font-size: 0.426667rem;
  line-height: 0.586667rem;
}

.warpContent .content .baseInfo .opinion {
  color: #1e9fff;
  font-size: 0.373333rem;
  line-height: 0.533333rem;
}

.warpContent .content .approver {
  margin: 0.053333rem 0 0.4rem 0;
}

.warpContent .content .approver .approverInfo {
  color: #666666;
  font-size: 0.373333rem;
  line-height: 0.533333rem;
  margin-left: 0.133333rem;
}

.warpContent .content .opinionDetails {
  padding: 0.266667rem;
  background-color: #fbfbfb;
  color: #333333;
  font-size: 0.373333rem;
  line-height: 0.533333rem;
}