.list_content {
  position: relative;
  padding-top: 0.266667rem;
  padding-bottom: 0.133333rem;
  border-bottom: 0.013333rem solid #c5c5c5;
}

.list_content p.fieldItem {
  font-size: 0.373333rem;
  color: #666666;
  line-height: 0.666667rem;
}

.list_content p.fieldItem span:first-child {
  display: inline-block;
  text-align: right;
}

.list_content p.fieldItem:first-child span:first-child {
  background-color: #1e9fff;
  border-radius: 0.106667rem 0 0.106667rem 0;
  line-height: 0.586667rem;
  color: #fff;
  padding: 0 0.16rem;
  margin-right: 0.133333rem;
  font-size: 0.32rem;
}

.list_content p.fieldItem span p {
  display: inline-block;
  margin: 0;
}

.list_content p.fieldState {
  position: absolute;
  top: 0.4rem;
  right: 0.4rem;
  color: #1e9fff;
  font-size: 0.32rem;
}

.list_content:nth-last-child(2) {
  padding-bottom: 0;
  border-bottom: 0;
}

.list_content .info_list_jiantou {
  position: absolute;
  top: 1.066667rem;
  right: 0.533333rem;
}

.loadMore {
  text-align: center;
  padding: 0.266667rem 0 0 0;
}

.loadMore span.hasData {
  font-size: 0.426667rem;
  color: #1e9fff;
}

.loadMore span.noData {
  font-size: 0.32rem;
  color: #a8a8a8;
}