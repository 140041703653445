.notFound {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.notFound img {
  width: 8.533333rem;
  height: 7.893333rem;
}

.notFound span {
  color: #fff;
  font-size: 0.48rem;
  padding: 0.266667rem 0.533333rem;
  margin-top: 0.533333rem;
  background-color: #108ee9;
  border-radius: 0.133333rem;
}