.homeBanner {
  width: 10rem;
  height: 1.626667rem;
  background-size: cover;
}

.menuPart {
  margin: 0.666667rem 0 -0.4rem 0;
}

.menuPart > h4 {
  color: #333333;
  font-size: 0.426667rem;
  padding-left: 0.266667rem;
  margin-bottom: 0.266667rem;
}

.menuList {
  display: flex;
  padding: 0 0.266667rem;
  flex-wrap: wrap;
}

.menuList .menuItemContainer {
  margin-right: 0.266667rem;
  margin-bottom: 0.266667rem;
}

.menuList .menuItemContainer:nth-child(3n) {
  margin-right: 0;
}

.menuList .nodata {
  color: gray;
  margin-bottom: 0.266667rem;
}