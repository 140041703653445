.textarea_s {
  width: 100%;
  position: relative;
}

.textarea_s span {
  position: absolute;
  bottom: 0.266667rem;
  right: 0.266667rem;
  color: #999999;
}