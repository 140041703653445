.loginContainer {
  width: 100%;
  height: 100%;
  text-align: center;
  position: absolute;
  top: 0;
  background-image: linear-gradient(134.35deg, #feffff 0%, #ffffff 29.71%, #d6e7ff 100%);
  color: #333;
  font-size: 0.373333rem;
}

.header {
  margin-top: 1.333333rem;
}

.header .logoContent {
  display: inline-block;
  width: 2.16rem;
  height: 2.16rem;
  margin-bottom: 0.133333rem;
  box-sizing: border-box;
  line-height: 2.16rem;
  padding: 0.026667rem;
  border-radius: 50%;
  background-image: linear-gradient(133.09deg, #942a2a 0%, #94baf9 100%);
}

.header .logoContent .logoInner {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: #fff;
}

.header .logoContent img {
  width: 1.146667rem;
  height: 1.546667rem;
}

.header h4 {
  color: #004098;
  font-size: 0.426667rem;
}

.loginForm {
  display: inline-block;
  margin-top: 1.52rem;
}

.loginForm .loginForm_input {
  width: 8.4rem;
  height: 5.2rem;
  padding: 0.933333rem 0.8rem;
  background-size: cover;
}

.loginForm .loginForm_input input {
  display: inline-block;
  width: 3.733333rem;
  margin-left: 0.613333rem;
  margin-right: 0.533333rem;
  color: #333333;
  font-size: 0.373333rem;
  background: transparent;
}

.loginForm .loginForm_input label {
  display: block;
  text-align: left;
  padding: 0.373333rem 0.266667rem;
  border-bottom: 0.026667rem solid rgba(30, 159, 255, 0.1);
}

.loginForm .loginForm_input svg {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.loginForm input[type='submit'] {
  width: 8.4rem;
  height: 1.28rem;
  margin-top: 0.8rem;
  background-color: #1e9fff;
  border-radius: 0.84rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  font-size: 0.426667rem;
  letter-spacing: 0.266667rem;
}