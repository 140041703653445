html {
  font-family: Helvetica Neue, Helvetica, Roboto, Segoe UI, Arial, sans-serif;
  /*谷歌浏览器字体最小字体12px*/
  -webkit-text-size-adjust: 100%;
  /*100%/none 关闭字体大小自动调整功能*/
  /*a标签及拥有 :active伪类的元素有默认的高亮框*/
  -webkit-tap-hightlight-color: transparent;
  /*禁止文本被选中*/
  -ms-user-select: none;
  user-select: none;
  -webkit-user-select: none;
}

body {
  font-family: Helvetica Neue, Helvetica, Roboto, Segoe UI, Arial, sans-serif;
  overflow-x: hidden;
  /*开启moblie网页快速滚动和回弹的效果*/
  -webkit-overflow-scrolling: touch;
  font-size: 0.32rem;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  display: block;
  width: 100%;
  color: #333;
  text-decoration: none;
}

img {
  border: 0;
  width: 100%;
  vertical-align: middle;
}

a,
img {
  /*阻止长按页面，弹出菜单的方法。*/
  -webkit-touch-callout: none;
}

input,
textarea {
  -webkit-appearance: none;
  outline: none;
  border: none;
}

.fl {
  float: left;
}

.fr {
  float: right;
}

.clearfix:after {
  content: '';
  display: block;
  width: 100%;
  height: 0;
  clear: both;
}

li,
ol {
  list-style: none;
}

/**
    错误提示
*/

.error_tip {
  color: red;
  text-align: left;
  height: 0.506667rem;
  font-size: 0.373333rem;
  line-height: 0.506667rem;
}

::-webkit-scrollbar {
  display: none;
}

.am-modal-content {
  padding-top: 0;
}